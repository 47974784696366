<template>
    <div class="">
        <div class="row">
            <div class="col-md-12" v-if="loading">
                <div class="row mt-5 pt-5">
                    <div class="col-md-12" v-loading="loading">

                    </div>
                </div>
            </div>
            <div class="col-md-12" v-if="!loading">
                <el-table :data="singleIndustry" :header-cell-style="{ background: 'rgba(212, 216, 226, 0.2)' }"
                    style="width: 100%" :empty-text="'No Data Available'" height="60vh">
                    <el-table-column label="Name" prop="firstname">
                        <template slot-scope="scope">
                            <p>
                                {{ scope.row.name }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column label="Phone" prop="phone">
                        <template slot-scope="scope">
                            <p>
                                {{ scope.row.user.phone }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column label="Email" prop="email">
                        <template slot-scope="scope">
                            <p>
                                {{ scope.row.user.email }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column label="Heard about us" prop="heard_about_us" width="">
                    </el-table-column>
                </el-table>
                <div class="col-md-12" style="text-align: center; margin-top:2em;">
                    <el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange"
                        :page-size="pageSize" :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import store from '@/state/store.js'
export default {
    name: 'admin-users',
    data() {
        return {
            loading: false,
            page: 1,
            pageSize: 50,
            total: 0,
            tableData: [],
        }
    },
    methods: {
        handleCurrentChange(val) {
            this.page = val;
        },
    },
    computed: {
        singleIndustry() {
            return this.tableData.individual.slice((this.page - 1) * this.pageSize, this.page * this.pageSize);
        },
    },
    beforeMount() {
        this.loading = true
        store.dispatch('admin/reporting/getIndustryReporting')
            .then((res) => {
                this.tableData = store.getters['admin/reporting/singleIndustry'](this.$route.params.id)
                this.total = this.tableData.individual.length
            })
            .finally(() => this.loading = false)
    }
}
</script>

<style scoped>
#enter__account {
    color: #F7921C;
}

button {
    outline: none;
    border: none;
    background: transparent;
    font-family: 'Graphik Regular';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
}

.create_notificaton {
    font-family: 'Graphik Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 159.3%;
    text-align: center;
    color: #FFFFFF;
    background: #F7921C;
    border-radius: 5px;
    outline: none;
    border: none;
    width: 239px;
    height: 38px;
}
</style>